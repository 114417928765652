import { isPlatformServer } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';

enum TAG {
  Id = 'Kyoungwoo@Id',
  AuthToken = 'Kyoungwoo@AuthToken',
  UserData = 'Kyoungwoo@UserData',
  memberAuthToken = 'Kyoungwoo@MemberAuthToken'
}

//export type UserType = 'PERSON' | 'PERSON' | 'COMPANY' | 'COMPANY'| 'TOBEWARE';
export type UserType = 'PERSON' | 'COMPANY';
export type UserRole = 
'PERSON_SYSTEM' // 시스템 마스터 - 0
|'PERSON_ADMIN' // 시스템 마스터 - 1
|'PERSON_PERSON'; // 시스템 마스터 - 2



export type UserGender = "남" | "여";
export type RequestState = "승인" | "대기";
export interface AuthToken {
  login_token:string;
  refresh_token:string;
}
export class UserData {
  account_id:string; // "worker_sh"
  language_type:string; // "kr"
  old_token_state:boolean; // false
  user_birth:string; // "1987-07-26"
  user_email:string; // "BOcilSPgIm@naver.com"
  user_gender:string; // "남"
  user_id:number; // 8573724717
  user_name:string; // "김수홍"
  user_phone:string; // "01000249857"
  user_profile:string; // "null"
  user_main_page:string; // "null"
  theme_project_id:number = 0;
  user_role:UserRole; // "PERSON_SYSTEM" | "PERSON_ADMIN" | "PERSON_PERSON"
  user_type:UserType; // "WORKER"
}
@Injectable({
  providedIn: 'root'
})
export class UserService {

  authToken:AuthToken;
  userData:UserData = new UserData();
  accountID:string;
  memberAuthToken:string;
  autoLogin:boolean = false;

  constructor(
    @Inject(PLATFORM_ID) private platformId
  ) {
    this.getId();
    this.getAuthToken();
    this.getUserData();
    this.getMemberAuthToken();
  }
  
  getId() {
    this.accountID = window.localStorage.getItem(TAG.Id) || null;
  }
  setId(accountID:string) {
    if(isPlatformServer(this.platformId)) return;

    window.localStorage.setItem(TAG.Id, accountID);
    this.getId();
  }
  removeId() {
    window.localStorage.removeItem(TAG.Id);
  }

  getAuthToken() {
    if(isPlatformServer(this.platformId)) return this.authToken = null; // ssr에서 auth token을 어떠헥 처리해야 하지? 쿠키로 해야하나? 아니면 정보를 안띄워야 하나?

    let storage:Storage;
    if(window.sessionStorage.getItem(TAG.AuthToken)) {
      storage = window.sessionStorage;
      this.autoLogin = false;
    } else if(window.localStorage.getItem(TAG.AuthToken)) {
      storage = window.localStorage;
      this.autoLogin = true;
    }
    
    if(storage) { this.authToken = JSON.parse(storage.getItem(TAG.AuthToken)) }
    else this.authToken = null;
  }
  setAuthToken(authToken:AuthToken, autoLogin:boolean = true) {
    if(isPlatformServer(this.platformId)) return;

    let storage:Storage = autoLogin ? window.localStorage : window.sessionStorage;
    let deleteStorage:Storage = autoLogin ? window.sessionStorage : window.localStorage;
    storage.setItem(TAG.AuthToken, JSON.stringify(authToken));
    deleteStorage.removeItem(TAG.AuthToken);

    this.getAuthToken();
  }
  getUserData() {
    if(isPlatformServer(this.platformId)) return this.userData = new UserData();

    let storage:Storage;
    if(window.sessionStorage.getItem(TAG.UserData)) {
      storage = window.sessionStorage;
      this.autoLogin = false;
    } else if(window.localStorage.getItem(TAG.UserData)) {
      storage = window.localStorage;
      this.autoLogin = true;
    }
    if(storage) { this.userData = JSON.parse(storage.getItem(TAG.UserData)) }
    else this.userData = new UserData();
  }
  async setUserData(data:UserData, autoLogin:boolean = true) {
    if(isPlatformServer(this.platformId)) return;

  
    let storage:Storage = autoLogin ? window.localStorage : window.sessionStorage;
    let deleteStorage:Storage = autoLogin ? window.sessionStorage : window.localStorage;
    storage.setItem(TAG.UserData, JSON.stringify(data));
    deleteStorage.removeItem(TAG.UserData);
    
    this.getUserData();
  }

  getMemberAuthToken() {
    this.memberAuthToken = window.localStorage.getItem(TAG.memberAuthToken) || null;
  }
  setMemberAuthToken(authToken:string) {
    if(isPlatformServer(this.platformId)) return;

    window.localStorage.setItem(TAG.memberAuthToken, authToken);
    this.getMemberAuthToken();
  }

  clear() {
    if(isPlatformServer(this.platformId)) return;

    window.localStorage.clear();
    window.sessionStorage.clear();

    this.getId();
    this.getMemberAuthToken();
    this.getAuthToken();
    this.getUserData();
  }

  /** utils */
  parseUserRole(userRole:number) {
    switch(userRole) {
      case 0:
        return '시스템 계정';
      case 1:
        return '일반어드민 계정';
      case 2:
        return '일반회원';
    }
  }
}