import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: '',
    //redirectTo: 'splash',
    redirectTo: 'landing',
    pathMatch: 'full'
  },
  {
    path: 'landing',
    loadChildren: () => import('./page/landing/landing.module').then(m => m.LandingPageModule)
  },
  //company (회사소개)
  {
    path: 'about',
    loadChildren: () => import('./page/company/about/about.module').then(m => m.AboutPageModule)
  },
  {
    path: 'bizarea',
    loadChildren: () => import('./page/company/bizarea/bizarea.module').then(m => m.BizareaPageModule)
  },
  {
    path: 'history',
    loadChildren: () => import('./page/company/history/history.module').then(m => m.HistoryPageModule)
  },
  {
    path: 'test',
    loadChildren: () => import('./page/company/test/history.module').then(m => m.TestPageModule)
  },
  {
    path: 'customer',
    loadChildren: () => import('./page/company/customer/customer.module').then(m => m.CustomerPageModule)
  },
  {
    path: 'address',
    loadChildren: () => import('./page/company/address/address.module').then(m => m.AddressPageModule)
  },
  //회사 소식
  {
    path: 'news', 
    loadChildren: () =>  import('./page/company/news/news-list/news-list.module').then((m) => m.NewsListPageModule)
  },
  //Solutions (제품)
  {
    path: 'ezpdfai',
    loadChildren: () => import('./page/solution/easyai/ezpdfai/ezpdfai.module').then(m => m.EzpdfaiPageModule)
  },  
  {
    path: 'loadrunner',
    loadChildren: () => import('./page/solution/opentext/loadrunner/loadrunner.module').then(m => m.LoadrunnerPageModule)
  },
  {
    path: 'qualitycenter',
    loadChildren: () => import('./page/solution/opentext/qualitycenter/qualitycenter.module').then(m => m.QualityCenterPageModule)
  },
  {
    path: 'uftone',
    loadChildren: () => import('./page/solution/opentext/uftone/uftone.module').then(m => m.UftOnePageModule)
  },
  {
    path: 'knime',
    loadChildren: () => import('./page/solution/knime/knime.module').then(m => m.KnimePageModule)
  },
  {
    path: 'rpa',
    loadChildren: () => import('./page/solution/rpa/rpa.module').then(m => m.RpaPageModule)
  },
  {
    path: 'whatap',
    loadChildren: () => import('./page/solution/whatap/whatap.module').then(m => m.WhatapPageModule)
  },
  //회원관련
  {
    path: 'login',
    loadChildren: () => import('./page/login/login/login.module').then(m => m.LoginPageModule)
  },
  {
    path: 'find-pc',
    loadChildren: () => import('./page/login/find-pc/find-pc.module').then(m => m.FindPcPageModule)
  },
  {
    path: 'lh-approval-list',
    loadChildren: () => import('./page/member-management/member-approval-wait/lh-approval-list/lh-approval-list.module'
      ).then((m) => m.LhApprovalListPageModule),
  },  
  {
    path: 'sign-up-person',
      loadChildren: () => import('./page/sign-up/sign-up-person/sign-up-person.module').then((m) => m.SignUpPersonPageModule
    ),
  },
  {
    path: 'sign-up-type',
    loadChildren: () => import('./page/sign-up/sign-up-type/sign-up-type.module').then((m) => m.SignUpTypePageModule
      ),
  },  
  {
    path: 'sign-up-terms',
    loadChildren: () => import('./page/sign-up/sign-up-terms/sign-up-terms.module').then((m) => m.SignUpTermsPageModule
      ),
  },  
  {
    path: 'sign-up-done',
    loadChildren: () =>
      import('./page/sign-up/sign-up-done/sign-up-done.module').then(
        (m) => m.SignUpDonePageModule
      ),
  },  
  {
    path: 'main-admin',
    loadChildren: () =>
      import('./page/main/admin/main-admin/main-admin.module').then(
        (m) => m.MainAdminPageModule
      ),
  },
  {
    path: 'vmstat',
    loadChildren: () =>
      import('./page/monitor/vmstat/vmstat.module').then(
        (m) => m.VmstatPageModule
      ),
  },
  {
    path: 'main-sub-admin',
    loadChildren: () =>
      import('./page/main/admin/main-sub-admin/main-sub-admin.module').then(
        (m) => m.MainSubAdminPageModule
      ),
  },
  //공지사항
  {
    path: 'notice-list',
    loadChildren: () =>
      import('./page/notice-board/notice/notice-list/notice-list.module').then(
        (m) => m.NoticeListPageModule
      ),
  },
  {
    path: 'notice-edit',
    loadChildren: () =>
      import('./page/notice-board/notice/notice-edit/notice-edit.module').then(
        (m) => m.NoticeEditPageModule
      ),
  },
  //뉴스
  {
    path: 'news-list',
    loadChildren: () =>
      import('./page/notice-board/news/news-list/news-list.module').then(
        (m) => m.NewsListPageModule
      ),
  },
  //Contact Us
  {
    path: 'contactus-list',
    loadChildren: () =>
      import('./page/notice-board/contactus/contactus-list/contactus-list.module').then(
        (m) => m.ContactUsListPageModule
      ),
  },
  //HealthCheck
  {
    path: 'healthcheck-list',
    loadChildren: () =>
      import('./page/notice-board/healthcheck/healthcheck-list/healthcheck-list.module').then(
        (m) => m.HealthCheckListPageModule
      ),
  },
  //마이페이지관리
  {
    path: 'my-page',
    loadChildren: () =>
      import('./page/my-page/my-page/my-page.module').then(
        (m) => m.MyPagePageModule
      ),
  },  
  {
    path: 'change-password',
    loadChildren: () =>
      import('./page/my-page/change-password/change-password.module').then(
        (m) => m.ChangePasswordPageModule
      ),
  },
  {
    path: 'change-phone',
    loadChildren: () =>
      import('./page/my-page/change-phone/change-phone.module').then(
        (m) => m.ChangePhonePageModule
      ),
  },  
  {
    path: 'open-close',
    loadChildren: () =>
      import('./page/company/history/open-close/open-close.component.module').then(
              (m) => m.OpenCloseComponentModule
      ),
  },  
  //AI-Stream
  {
    path: 'ai-stream',
    loadChildren: () =>
      import('./page/ai/ai-stream/ai-stream.module').then(
        (m) => m.AiStreamPageModule
      ),
  },    
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      //preloadingStrategy: PreloadAllModules
    })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
